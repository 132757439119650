<template>
  <formset v-bind="formsetProps" @updated="handleUpdate">
    <template #sum-title>
      <div class="c-formset__col">
        {{ subtotalColLabel }}
      </div>
    </template>
    <template #sum-column="{row}">
      <div class="c-formset__col" data-test="formset-col">
        <div class="bg-gray-200 form-control text-end">
          {{ asCurrency(getRowSum(row)) }}
        </div>
      </div>
    </template>
  </formset>

  <div class="c-formset">
    <div class="c-formset__row">
      <div v-for="(col, index) in columnsWithSumColAdded" :key="col.key" class="c-formset__col text-end">
        <template v-if="isPreceedingLastColumn(index)">
          <label class="form-label">{{ sumLabel }}</label>
        </template>
        <template v-if="isLastColumn(index)">
          <div class="bg-gray-200 form-control p-1" data-test="sum-value">
            {{ sum }}
          </div>
        </template>
      </div>
      <div class="c-formset__col c-formset__col--actions" />
    </div>
  </div>
</template>

<script>
import Formset from '@/js/components/Formset.vue';
import {asCurrency, toNumber} from '@canopyllc/roots/utils/stringUtil.js';
import {roundHalfEven} from '@canopyllc/roots/utils/number.js';

/**
 * SIMPLE FORM ONLY WRAPPER
 */
export default {
  name: 'FormsetStaffing',
  components: {Formset},
  props: {
    sumLabel: {
      type: String,
      default: 'Total',
    },
    subtotalColLabel: {
      type: String,
      required: true,
    },
    sumColumnKey: {
      type: String,
      required: true,
    },
    multiplierColumnKey: {
      type: String,
      required: false,
      default: '',
    },
    // @see Formset.vue for prop types
    formsetProps: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {asCurrency};
  },
  data() {
    return {
      localFormsetData: [],
    };
  },
  computed: {
    colCount() {
      return this.columnsWithSumColAdded.length;
    },
    sum() {
      return asCurrency(this.localFormsetData.reduce((aggregate, rowData) => aggregate + this.getRowSum(rowData), 0));
    },
    columnsWithSumColAdded() {
      return [...this.formsetProps.columns, {key: 'sum-column'}]; // do this so sum at bottom is aligned properly
    },
  },
  mounted() {
    // ASSUMES THAT FORMSETDATA PROP WILL NEVER CHANGE AFTER MOUNT since we are only supporting simple form usage atm
    // IF THAT CHNAGES, USE A WATCHER
    this.localFormsetData = this.formsetProps.formsetData;
  },
  methods: {
    getRowSum(rowData) {
      const multiplierValue = toNumber(rowData[this.multiplierColumnKey] || 1),
            parsedValue = toNumber(rowData[this.sumColumnKey]);

      // Ensure that when we add up the subtotal rows, we're operating on the same values
      // that we show to the user in the total (so the row sum doesn't appear incorrect).
      // To do this, we'll format the number with the same rounding method we use in python,
      // then parse it back into a number for summation.
      return roundHalfEven(parsedValue * multiplierValue);
    },
    isLastColumn(index) {
      return index === (this.colCount - 1);
    },
    isPreceedingLastColumn(index) {
      return index === (this.colCount - 2);
    },
    handleUpdate(data) {
      this.localFormsetData = data;
    },
  },
};
</script>
